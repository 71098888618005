import { HttpClient } from '@angular/common/http';
import { Injector, Directive } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';

import { DatabaseConfiguration } from '../models/database-configuration';
import { ReloadBehavior } from '../models/reload-behaviour';
import { FilterService } from '../services/filter.service';
import { BaseComponent } from './base-component';
import { BaseService } from '../services';
import { Response } from '@app/shared/services/local/Response';
import { __ } from '@app/shared/functions/object.functions';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '@app/core/i18n.service';

@Directive()
export class BaseDatabase<T> extends BaseComponent {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  currentSet: T[] = [];

  reload$: Subject<any> = new Subject<any>();

  isLoading$: Subject<any> = new Subject<boolean>();

  filterValuesChanged$: BehaviorSubject<any> = null;

  filterService: FilterService = null;

  filtersApplied: boolean = false;

  currentPageIndex: number = 0;

  previousSort: string;

  previousPaging: string;

  previousFilter: string;

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  private httpClient: HttpClient;

  private i18nService: I18nService;

  private _dataChanged$: Subject<T[]> = new Subject<T[]>();

  private _disrupt$: Subject<boolean> = new Subject<boolean>();

  // tslint:disable-next-line:member-ordering
  dataChanged$: Observable<T[]> = this._dataChanged$.asObservable();

  // tslint:disable-next-line:member-ordering
  disrupt$: Observable<boolean> = this._disrupt$.asObservable();

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    public configuration: DatabaseConfiguration,
    private injector: Injector,
    public paginator: MatPaginator,
    public sort: MatSort,
    private filterValuesChanged: BehaviorSubject<any> = null,
    private reflectFiltersInUrl: boolean = false,
    private flatFiltering: boolean = false,
    private idSelector?: (q: T) => string | number
  ) {
    super();

    this.httpClient = this.injector.get(HttpClient);
    this.i18nService = this.injector.get(I18nService);

    this.filterValuesChanged$ = this.filterValuesChanged;

    this.filterService = new FilterService(
      this.filterValuesChanged$,
      this.reflectFiltersInUrl,
      this.flatFiltering,
      injector,
      configuration,
      paginator,
      sort
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  /**
   *
   * Updates the data set of the database and notifies subscribing members of the data change.
   * The update can either be performed in the backend or locally, depending on the provided reload behavior.
   *
   * @param reloadBehavior The reload behavior that should be used for this update
   */
  update(reloadBehavior: ReloadBehavior<T>): Observable<T[]> {
    if (__.IsNullOrUndefined(reloadBehavior)) {
      return of([]);
    }

    let filters = this.filterService.buildFilters();

    if (!__.IsNullOrUndefined(this.paginator) && (this.previousSort !== filters.sorts || this.previousFilter !== filters.filters)) {
      this.paginator.pageIndex = 0;
      filters = this.filterService.buildFilters();
    }

    this.previousFilter = filters.filters;
    this.previousSort = filters.sorts
    this.previousPaging = `skip=${filters.paging.skip}&take=${filters.paging.take}`;

    if (!__.IsNullOrUndefinedOrEmpty(filters.filters)) {
      this.filtersApplied = true;
    } else {
      this.filtersApplied = false;
    }

    // Either load data from the backend or reload the table with provided local items
    if (reloadBehavior.fromBackend) {
      this.isLoading = true;
      this.isLoading$.next(true);
      this.currentSet = [];
      this._dataChanged$.next([]);

      let observable = null;
      let headers: { [header: string]: string | string[]; } = null;

      if (this.configuration.fullLanguageSupport) {
        headers = { 'Accept-Language': this.i18nService.supportedLanguages.map(q => q.locale).join(',') };
      } else {
        headers = { 'Accept-Language': this.i18nService.language };
      }

      if (this.configuration.useBearerAuthentication) {
        observable = this.httpClient.disableAcceptedLanguage().get<any>(this.filterService.buildFilterUrl(filters), { headers });
      } else {
        observable = this.httpClient
          .disableAccessToken()
          .disableAcceptedLanguage()
          .enableBasicAuthorization()
          .get<any>(this.filterService.buildFilterUrl(filters), { headers });
      }

      return observable.pipe(
        delay(0),
        map((result: Response<T[]>) => {
          this.isLoading$.next(false);
          this.isLoading = false;
          this.currentSet = result.data;

          if (!__.IsNullOrUndefined(this.paginator)) {
            this.paginator.length = result.total;
            if (this.currentPageIndex === this.paginator.pageIndex) {
              this.paginator.pageIndex = 0;
            }
            this.currentPageIndex = this.paginator.pageIndex;
          }
          this._dataChanged$.next(result.data);
          this._disrupt$.next(true);
          return this.currentSet;
        }),
        catchError((error: any) => {
          console.log(
            'It seems like the server is not accessible. Please make sure that you are connected to the internet.'
          );
          return of(null);
        })
      );
    } else {

      if (reloadBehavior.placeholders === false) {
        this.isLoading$.next(false);
        this.isLoading = false;
      }

      this.currentSet = reloadBehavior.items;
      // this.paginator.length = reloadBehavior.items.length;
      this._dataChanged$.next(reloadBehavior.items);
      this._disrupt$.next(true);
      return of(reloadBehavior.items);
    }
  }

  /**
   * Removes the given items from the provided resource in the backend
   *
   * @param items The items that should be removed
   */
  removeItems(items: T[]): Observable<boolean> {
    let ids = '';
    if (items.length > 0) {
      items.forEach((item: any, index: number) => {
        if (index !== 0) {
          ids = `${ids}&`;
        }
        ids = `${ids}ids=${this.idSelector(item).toString()}`;
      });
    }

    return this.httpClient
      .delete(`${this.configuration.resource.toString()}?${ids}`)
      .pipe(
        map((result: any) => {
          return true;
        })
      )
  }
}
