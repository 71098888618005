import { ImageVariation } from '@app/shared/models/classes/Image';
import { environment } from '@env/environment';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';

import { FileService } from '../file.service';
import { FileContainerBase } from './FileContainer';
import { FileState } from './FileState.enum';
import { FileType } from './FileType.enum';
import { LoadingState } from './LoadingState.enum';

export abstract class FileBase {
  id: string;

  parent: FileContainerBase;

  description: string = '';

  loadingState: LoadingState = LoadingState.Initial;

  kind: string;

  abstract state: FileState;

  abstract fileType: FileType;

  name: string;

  size: number;

  mimeType: string;

  date: string;

  private _source: any;
  private _source$: Subject<any> = new Subject<any>();
  source$: Observable<any> = this._source$.asObservable();

  get source(): string | ArrayBuffer {
    return this._source;
  }
  set source(source: string | ArrayBuffer) {
    this._source = source;
    this._source$.next(source);
  }

  private _loadingProgress: number;
  private _loadingProgress$: Subject<number> = new Subject<number>();
  loadingProgress$: Observable<number> = this._loadingProgress$.asObservable();
  get loadingProgress(): number {
    return this._loadingProgress;
  }
  set loadingProgress(progress: number) {
    if (progress > 100) {
      progress = 100;
    }
    this._loadingProgress = progress;
    this._loadingProgress$.next(progress);

    this.parent.setLoadingProgressForFile(this, progress);
  }

  private _dataUrl: string;
  private _dataUrl$: Subject<string> = new Subject<string>();
  dataUrl$: Observable<number> = this._loadingProgress$.asObservable();
  get dataUrl(): string {
    return this._dataUrl;
  }
  set dataUrl(dataUrl: string) {
    this._dataUrl = dataUrl;
    this._dataUrl$.next(dataUrl);
  }

  constructor() {
    this.date = moment()
      .utc()
      .toISOString();
  }
}

export class NewFile extends FileBase {
  state = FileState.New;

  fileType = FileType.TrackingLabelReturn;

  constructor(public file: File) {
    super();

    this.name = file.name;
    this.mimeType = FileService.getMimeTypeByFileName(file.name);
    this.size = file.size;
    this.id = FileService.NewGuid();
  }
}

export class ExistingFile extends FileBase {
  state = FileState.Existing;

  fileType = FileType.TrackingLabelReturn;

  constructor(id: string, name: string, size: number, kind: string) {
    super();

    this.name = name;
    this.mimeType = FileService.getMimeTypeByFileName(name);
    this.size = size;
    this.kind = kind;
    this.id = id;
    this.dataUrl = `${environment.serverUrl}files/${id}`;
  }
}

export class NewImage extends FileBase {
  state = FileState.New;

  fileType = FileType.TrackingLabel;

  constructor(public file: File) {
    super();

    this.name = file.name;
    this.mimeType = FileService.getMimeTypeByFileName(file.name);
    this.size = file.size;
    this.id = FileService.NewGuid();
    // this.dataUrl = file;
  }
}

export class ExistingImage extends FileBase {
  state = FileState.Existing;

  fileType = FileType.TrackingLabel;

  constructor(id: string, name: string, size: number, kind: string) {
    super();

    this.name = name;
    this.mimeType = FileService.getMimeTypeByFileName(name);
    this.size = size;
    this.kind = kind;
    this.id = id;
    this.dataUrl = `${environment.serverUrl}files/${id}`;
  }
}

export class ExistingImageWithVariations extends FileBase {
  state = FileState.Existing;

  fileType = FileType.TrackingLabel;

  constructor(id: string, public variations: ImageVariation[]) {
    super();

    this.id = id;
  }
}

