import { NgModule } from '@angular/core';

import { LoadingModule } from '../loading/loading.module';
import { ImageComponent } from './image.component';
import { SharedModule } from '../../shared.module';

@NgModule({
   imports: [
      SharedModule,
      LoadingModule
   ],
   declarations: [
      ImageComponent,
   ],
   exports: [
      ImageComponent
   ]
})
export class ImageModule { }
