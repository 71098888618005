import { Location } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { __ } from '@app/shared/functions/object.functions';
import { BehaviorSubject } from 'rxjs';

import { DatabaseConfiguration } from '../models/database-configuration';
import { AggregateFilter, Filter } from '../models/filter';
import { FilterURI } from '../models/filter-uri';
import { Paging } from '../models/paging';
import { FilterUtilsService } from './filter-utils.service';


/**
 *
 * Service which provides functions for filtering based on changes that occur in a form.
 * Supported types of formcontrols that can be used for filtering are:
 * - checkboxes,
 * - sliders,
 * - datepickers
 * - objects (with prefixes like "objectName.property"),
 * - simple strings like values picked from a selection or search terms
 *
 * Nested/Dynamic filtering is also supported which means,
 * that there are arrays of nested formgroups of formcontrols that are used for filtering.
 *
 */
export class FilterService {
    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC INSTANCE VARIABLES
    // -----------------------------------------------------------------------------------------------------

    isReload: boolean = true;

    // -----------------------------------------------------------------------------------------------------
    // @ CONSTRUCTOR
    // -----------------------------------------------------------------------------------------------------

    constructor(
        private filterValuesChanged$: BehaviorSubject<any>,
        private reflectFiltersInUrl: boolean = false,
        private flatFiltering: boolean = false,
        private injector: Injector,
        private configuration: DatabaseConfiguration,
        private paginator?: MatPaginator,
        private sort?: MatSort,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC METHODS
    // -----------------------------------------------------------------------------------------------------

    /**
     * Builds the filters for the sorting, paging and additional query parameters.
     * Also sets the resource string which represents the api endpoint to which the request is sent.
     */
    buildFilters() {
        const filterUri = {
            sorts: this.buildSortFilter(),
            paging: this.buildPaging(),
            resource: this.buildResourceString(),
            filters: ''
        } as FilterURI;

        // * use FilterURI object to prevent the string from being empty when using recursion (call by reference instead of call by value)
        this.buildAdditionalFiltersString(filterUri);

        return filterUri;
    }

    /**
     * Builds the filter url containing the filter query parameters the sorting string, the paging and the resource string.
     * Adds '&' before the filters if the filters don't start with a '&' character already.
     * @param dataFilter FilterURI object that contains all relevant information (queryparams of filters, paging, sorting 
     * and the resource string for the request route) to build the filter URI for the backend request.
     */
    buildFilterUrl(dataFilter: FilterURI): string {

        // set '&' manually for a parameter if it is missing or if there are empty queryParams
        const baseFilterUrl = `${dataFilter.resource}${dataFilter.sorts}skip=${dataFilter.paging.skip}&take=${dataFilter.paging.take}`;

        // * remove id from filter url when in aggregate filter
        const filters = this.removeIdFromFilterUrl(dataFilter.filters);

        if (filters.startsWith('&') || __.IsNullOrUndefinedOrEmpty(filters)) {
            return `${baseFilterUrl}${filters}`;
        } else {
            return `${baseFilterUrl}&${filters}`;
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ PRIVATE METHODS
    // -----------------------------------------------------------------------------------------------------

    /**
     * Fixes resource string and sets "?" and "&" based on the position of the "?".
     */
    private buildResourceString(): string {
        let resourceString = this.configuration.resource;

        if (this.configuration.resource.toString().indexOf('?') === -1) {
            resourceString = resourceString + '?';
        } else {
            resourceString = resourceString + '&';
        }

        return resourceString;
    }

    /**
     * Checks if sorting is activated and prefixes the filter string with the sorting direction and the column that has to be sorted.
     * Filter string that is used for sorting (orderby= for asc and orderby=- for desc) is determined by the current sort direction.
     */
    private buildSortFilter(): string {
        let sorts = '';

        if (!__.IsNullOrUndefined(this.sort)) {
            const sortField = this.sort.active;
            const sortDirection = this.sort.direction;

            // Build sorting
            if (!__.IsNullOrUndefinedOrEmpty(sortField) && !__.IsNullOrUndefinedOrEmpty(sortDirection)) {
                switch (sortDirection) {
                    case 'asc':
                        sorts = sorts + 'orderby=';
                        break;
                    case 'desc':
                        sorts = sorts + 'orderby=-';
                        break;
                    default:
                        break;
                }
                sorts = sorts + `${sortField}&`;
            }
        }

        return sorts;
    }

    /**
     * Determines the values for skip and take for the paging of the elements in the table.
     */
    private buildPaging(): Paging {

        let skip = 0;
        let take = 100;

        if (!__.IsNullOrUndefined(this.paginator)
            && !__.IsNullOrUndefined(this.paginator.pageSize)
            && !__.IsNullOrUndefined(this.paginator.pageIndex)) {

            const pageIndex = this.paginator.pageIndex;
            const pageSize = this.paginator.pageSize;

            skip = pageSize * pageIndex;
            take = pageSize;
        }

        return { skip, take } as Paging;
    }

    /**
     * Remove all occurences of the 'id' of the formgroup out of the filter string so that is only shown in the url but not sent to backend.
     * @param filters string that cotains all query parameters
     */
    private removeIdFromFilterUrl(filters: string) {

        if (filters.includes('af=')) {
            const decodedFilter = decodeURIComponent(filters);
            // find first occurence of aggregate filter
            const afFirstIndex = decodedFilter.indexOf('&af=');

            // also split the other filters that are not aggregate filters and save them to concatenate the string after removing the id
            const filterSubStringWithoutAggregateFilters = decodedFilter.substring(0, afFirstIndex);

            // just get the aggregate filters...
            const afFilter = decodedFilter.substring(afFirstIndex, decodedFilter.length);
            // ... and split them at the positions with '&' character
            const splitAfFilters = afFilter.split('&');

            let afQueryParamsWithoutId = '';
            // * foreach filter JSON parse and remove id
            for (const aggregateFilter of splitAfFilters) {
                if (aggregateFilter.includes('"id":')) {
                    const decodedFilterObjectAsJSON = aggregateFilter.replace('af=', '');

                    const afObject = JSON.parse(decodedFilterObjectAsJSON);
                    delete afObject.id; // remove id from object and reparse again
                    const afObjectWithoutId = JSON.stringify(afObject);
                    afQueryParamsWithoutId = `${afQueryParamsWithoutId}&af=${afObjectWithoutId}`;
                }

            }

            const filterWithoutAggregateFilterIds = filterSubStringWithoutAggregateFilters + afQueryParamsWithoutId;

            return filterWithoutAggregateFilterIds;
        }
        return filters;
    }

    /**
     * Build filter string for all other filters supporting checkboxes, sliders (primitive values and objects),
     * date (objects) and search string.
     * Uses the change value of the filtersform as base to retrieve the filters.
     * Also sets the URL in the browser afterwards.
     * @param filterUri FilterURI object that is used to store all relevant information (queryparams of filters, paging, sorting
     * and the resource string for the request route) in it. FilterURI should already contain all the information but the queryparams.
     */
    private buildAdditionalFiltersString(filterUri: FilterURI) {

        if (!__.IsNullOrUndefined(this.filterValuesChanged$)) {

            const formArray = this.filterValuesChanged$.value;

            if (!__.IsNullOrUndefined(formArray)) {

                this.buildFilterBasedOnForm(filterUri, formArray);

                // * NOTE: use this for selecting the necessary information as flat list when filtering the table
                // * (because all the additional nested information is not needed)
                if (!__.IsNullOrUndefinedOrEmpty(filterUri.filters) && this.flatFiltering) {
                    filterUri.filters = `${filterUri.filters}&select=`;
                }

                this.setBrowserUrl(filterUri.filters);
            }

            if (this.isReload) {
                this.isReload = false;
            }
        }
    }

    /**
     * Sets the url with the filter queryparams in the browser if the reflectFiltersInUrl property is set to true.
     * Removes unnecessary characters like "&" and "," in the queryParameters string.
     *
     * @param queryParameters The queryParameters that contain all the filter values.
     */
    private setBrowserUrl(queryParameters: string) {

        /*
         * NOTE: workaround for now --> check if the call that is made is not the first one,
         * because this would cause conflicts with retaining the state of the filter values of the filters form
         * and the valuesChange event triggering the filtering (see calculateFilterValues() call in filter-utils.service)
         */

        if (!this.isReload && this.reflectFiltersInUrl === true) {
            const location: Location = this.injector.get(Location);
            if (queryParameters.startsWith('&') || queryParameters.startsWith(',')) {
                queryParameters = queryParameters.substr(1); // trim queryParameters string if it starts with '&'
            }

            location.replaceState(window.location.pathname, queryParameters);
        }

    }

    /**
     * Builds the filters based on all form controls in the form and save them in the filters property of the queryParameters.
     * The building of the filter is done based on the given formArray.
     *
     * @param queryParameters The queryParameters that are filled with the filter values.
     * @param formArray Contains all the filterable formcontrols.
     */
    private buildFilterBasedOnForm(queryParameters: FilterURI, formArray: any) {

        for (const key in formArray) {
            if (formArray.hasOwnProperty(key)) {
                const formValue = formArray[key];
                // * used for checkboxes to store all the OR concatenated checked values of the checkbox
                const checkboxORConcatenatedArray: Filter[] = [];

                // * if the value is part of an array
                if (formValue instanceof Array) {

                    for (let index = 0; index < formValue.length; index++) {
                        const formElement = formValue[index];

                        if (key === 'aF') {
                            if (!__.IsNullOrUndefined(formElement)) {
                                this.calculateAggregateFilters(formElement, queryParameters);
                            }

                        } else {
                            if (FilterUtilsService.isObject(formElement)) {
                                // * if checkbox value is checked
                                if (formElement.value === true) {
                                    // * add key to the array of checkbox values

                                    checkboxORConcatenatedArray.push(formElement);

                                }
                            } else {

                                // * NOTE: use this for sliders or ranges if the type is primitive
                                this.setQueryParamsForSliders(index, key, queryParameters, formElement);
                            }
                        }
                    }

                    // * OR concatenation of the queryparams for the checkboxes
                    this.concatenateORSeperatedCheckboxFilterValues(checkboxORConcatenatedArray, queryParameters, key);
                } else {

                    // * do the filtering for elements/objects that are not part of an array
                    this.filterForSingleElement(formValue, queryParameters, key);
                }
            }
        }
    }

    /**
     * Calculate the dynamic/aggregate filters and parse the JSON string that should be send as additional query parameter.
     * @param formElement element that contains and AggregateFilter object
     */
    private calculateAggregateFilters(aggregateFilterElement: AggregateFilter, queryParameters: FilterURI) {

        // create main filter
        const aggregateFilterJson = this.createAggregateFilter(aggregateFilterElement);
        const aggregateFilter = `af=${aggregateFilterJson}`;
        queryParameters.filters = `${queryParameters.filters}&${aggregateFilter}`;
    }

    private createAggregateFilter(aggregateFilterElement: AggregateFilter) {

        const stringifiedAggregateFilerJSON = JSON.stringify(aggregateFilterElement);

        return stringifiedAggregateFilerJSON;
    }

    /**
     * Do the filtering for a single element or object that can either be:
     * - a simple string or a boolean (toggle element that only has two values (true or false))
     * - a range slider with an object name as prefix
     * - a date object or date range
     *
     * @param value Contains the object on which the filtering based on.
     * @param queryParameters The queryParameters that already contain filter values.
     * @param key The key is the key that is used to identify the filter.
     */
    private filterForSingleElement(value: any, queryParameters: FilterURI, key: string) {
        let ignoreElement = false;
        // if there is one value and it is an object
        if (!__.IsNullOrUndefinedOrEmpty(value)) {
            let urlParameterValue = null;

            // NOTE: do this for a slider using the current filter model to support nested objects like "key.objectName >= number"
            // * also make sure that the object is not a date object (Moment object used in the datepicker)
            if (typeof value === 'object' && __.IsNullOrUndefined(value.toDate)) {
                for (const objectName in value) {
                    if (value.hasOwnProperty(objectName)) {
                        const element = value[objectName];

                        // there are only two elements in the array (slider min and slider max value)
                        // * make sure that the slider is nested in another form group to make it possible for
                        queryParameters.filters = `${queryParameters.filters}&${key}.${objectName}>=${element[0]}`;
                        queryParameters.filters = `${queryParameters.filters}&${key}.${objectName}<=${element[1]}`;

                    }
                }
                // * ignore element if it is a slider or a date range
                ignoreElement = true;
            } else if (!__.IsNullOrUndefined(value.toDate)) {
                // * dateField as moment object that needs to be casted to Date
                urlParameterValue = value.toDate().toISOString();
            } else if (Object.prototype.toString.call(value) === '[object Date]') {
                // * simple date string in the format of DateTime
                urlParameterValue = value.toISOString();
            } else if (typeof value === 'string' || typeof value === 'boolean' || FilterUtilsService.isNumber(value)) {
                // * set for filter string or primitive string or boolean
                urlParameterValue = value;
            }

            // * ignore element if it is a slider or date range and the min and max values were already added to queryParameters.filters
            if (!ignoreElement) {

                if (key.endsWith('Start')) {
                    const dateFieldName = key.slice(0, key.indexOf('Start')); // extract the suffix to have just the form control name
                    this.setQueryParamString(queryParameters, '>=', dateFieldName, urlParameterValue);
                } else if (key.endsWith('End')) {
                    const dateFieldName = key.slice(0, key.indexOf('End')); // extract the suffix to have just the form control name
                    this.setQueryParamString(queryParameters, '<=', dateFieldName, urlParameterValue);
                } else {
                    this.setQueryParamString(queryParameters, '=', key, urlParameterValue);
                }
            }
        }
    }

    private setQueryParamString(queryParameters: FilterURI, operator: string, key: string, urlParameterValue: string) {
        queryParameters.filters = `${queryParameters.filters}&${key}${operator}${urlParameterValue}`;
    }

    /**
     * Sets the queryParameters for the slider ranges using >= and <= to mark the range.
     *
     * @param index The index of the array of values for the slider. The first on marks the start value.
     * @param key The key is the key that is used to identify the filter.
     * @param queryParameters The queryParameters that already contain filter values.
     * @param element String that contains the value of the slider element.
     */
    private setQueryParamsForSliders(index: number, key: string, queryParameters: FilterURI, element: any) {
        const urlParameterName = index === 0 ? `${key}>=` : `${key}<=`;

        if (!__.IsNullOrUndefined(element)) {
            queryParameters.filters = `${queryParameters.filters}&${urlParameterName}${element}`;
        }
    }


    /**
     * Takes all the strings from the given array and concatenates them by seperating them with commas.
     *
     * @param checkboxOrConcatenatedArray String array that contains all the values which are checked in the form
     * and which need to be concatenated.
     * @param queryParameters The queryParameters that already contain filter values.
     * @param key The key is the key that is used to identify the filter.
     */
    private concatenateORSeperatedCheckboxFilterValues(checkboxOrConcatenatedArray: Filter[], queryParameters: FilterURI, key: string) {
        if (!__.IsNullOrUndefinedOrEmpty(checkboxOrConcatenatedArray)) {
            let checkboxOrConcatenatedString = '';
            let prefix = '';

            for (let index = 0; index < checkboxOrConcatenatedArray.length; index++) {
                const element = checkboxOrConcatenatedArray[index];

                if (index !== 0) {
                    checkboxOrConcatenatedString = `${checkboxOrConcatenatedString},${element.key}`;
                } else {
                    checkboxOrConcatenatedString = element.key;
                }
                // * assume that checkboxes Filter objects have the same prefix
                prefix = element.prefix;
            }
            queryParameters.filters = this.setQueryParamsForCheckboxes(checkboxOrConcatenatedString, queryParameters.filters, key, prefix);
        }
    }

    /**
     * Adds the concatenated valueString to the queryparameters string.
     * The values are corresponding to the given key and are based on a checked checkbox in the form.
     *
     * @param valueString String that contains the or concatenated values which are separated by comma (e.g. value1, value2, ...).
     * @param queryParameters The queryParameters that already contain filter values.
     * @param key The key is the key that is used to identify the filter.
     * @param prefix The prefix is used for object like filter keys
     * (e.g. window.width=1000 and "window" being the prefix to the key)
     */
    private setQueryParamsForCheckboxes(valueString: string, queryParameters: string, key: string, prefix?: string,): string {

        if (__.IsNullOrUndefinedOrEmpty(prefix)) {
            queryParameters = `${queryParameters}&${key}=${valueString}`;
        } else {
            queryParameters = `${queryParameters}&${prefix}.${key}=${valueString}`;
        }

        return queryParameters;
    }
}
