<mat-menu #appMenu="matMenu">
  <ng-container *ngIf="menuItems.length === 0 || anyItemsVisible() === false">
    <button mat-menu-item>
     {{'General.No actions available' | translate}}
    </button>
  </ng-container>

  <ng-container *ngFor="let menuItem of menuItems">
    <ng-container *ngIf="checkIsVisible(menuItem) === true">
      <button [ngClass]="menuItem.customClass" mat-menu-item (click)="itemClick(menuItem)" [disabled]="checkDisabled(menuItem) === true">
        {{ menuItem.displayName }}
      </button>
    </ng-container>
  </ng-container>
</mat-menu>

<button (click)="onMenuClicked.emit(true)" [disabled]="disabled" mat-icon-button [matMenuTriggerFor]="appMenu">
  <mat-icon [svgIcon]="icon"></mat-icon>
</button>