import { CurrencyPipe, KeyValue } from '@angular/common';
import { Directive, Injector } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { __ } from '@app/shared/functions/object.functions';
import { BaseModel } from '@app/shared/models/classes/BaseModel';
import moment from 'moment';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { Filter } from '../models/filter';
import { ReloadBehavior } from '../models/reload-behaviour';
import { SearchFilter } from '../models/search-filter';
import { FilterUtilsService } from '../services/filter-utils.service';
import { FiltersFormUtilsService } from '../services/filters-form-utils.service';
import { BaseCollectionComponent } from './base-collection.component';


@Directive()
export abstract class BaseFilterableComponent<T extends BaseModel> extends BaseCollectionComponent<T> {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  filtersForm: UntypedFormGroup;

  searchFilterFormControl = new UntypedFormControl('');

  filterValuesChanged$ = new BehaviorSubject<any>(null);

  searchTermEnter$ = new BehaviorSubject<SearchFilter>(null);

  reload$ = new BehaviorSubject<any>(null);

  getAsFormArray = FiltersFormUtilsService.getAsFormArray;

  filtersFormValue: any = {};

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  private currencyPipe: CurrencyPipe;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(protected injector: Injector) {
    super(injector);

    this.currencyPipe = this.injector.get(CurrencyPipe);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  /**
   * Merges the valueChanges event of the filtersForm and the BehaviourSubject for the search filter
   * and maps the objects to one JSON object so that it can be filtered.
   * "filter" is first element in JSON object.
   */
  initAndMergeObservables() {
    const searchText = this.searchFilterFormControl.value;

    // * workaround for now: emit search filter to make combine latest to work
    if (__.IsNullOrUndefinedOrEmpty(searchText)) {
      this.emitSearchEvent('');
    } else {
      this.emitSearchEvent(searchText);
    }

    if (this.filtersForm.value.length > 0) {
      const merged = merge(
        this.filtersForm.valueChanges.pipe(debounceTime(300)),
        this.searchTermEnter$
      ).pipe(
        map(x => {
          if (!this.IsNullOrUndefined(x['filter'])) {
            const search = this.searchFilterFormControl.value;
            if (!__.IsNullOrUndefinedOrEmpty(search)) {
              const searchFilter = {
                filter: search
              };

              const mergedFilters = Object.assign(searchFilter, x[0]);
              return mergedFilters;
            } else {
              return this.filtersFormValue;
            }
          }
          this.filtersFormValue = x;
          return x;
        })
      );

      this.filterValuesChanged$ = this.convertObservableToBehaviorSubject(merged);
    } else {
      const search = this.searchTermEnter$.pipe(
        map(x => {
          if (!this.IsNullOrUndefined(x['filter'])) {
            const searchValue = this.searchFilterFormControl.value;
            if (!__.IsNullOrUndefinedOrEmpty(search)) {
              const searchFilter = {
                filter: searchValue
              };

              const mergedFilters = Object.assign(searchFilter);
              return mergedFilters;
            } else {
              return this.filtersFormValue;
            }
          }
          this.filtersFormValue = x;
          return x;
        })
      );

      this.filterValuesChanged$ = this.convertObservableToBehaviorSubject(search);
    }
  }

  listenForEmptySearchFilter() {
    // * reset the filter if the value of the search term is empty
    super.addSubscription(
      this.searchFilterFormControl.valueChanges.subscribe((searchTerm: string) => {
        if (searchTerm === '') {
          this.emitSearchEvent(searchTerm);
        }
      })
    );
  }

  initFilterValuesChanges() {
    const test = this.filtersForm.valueChanges.pipe(debounceTime(300));

    this.reload$ = this.convertObservableToBehaviorSubject(test);
  }

  /**
   * Emits the search event after the user presses the enter key with the search term that was entered in the search filter form control.
   *
   * @param $event KeyboardEvent which holds event information.
   */
  startSearch($event: KeyboardEvent | string) {
    const searchTerm = this.searchFilterFormControl.value;
    this.emitSearchEvent(searchTerm);
  }

  /**
   * This function recalculates the filter values and sets them based on the filter URI in the browser.
   * This is needed when the page is reloaded to retain the state of the filters.
   */
  calculateFilterValues() {
    if (!__.IsNullOrUndefined(this.filtersForm)) {
      // * NOTE: for all browsers except the IE 11 (and lower versions) the query params in the browser
      // * can be evaluated via "URLSearchParams"
      // * for the IE 11 the URL and the query params should be evaluated manually with following code:
      const search = window.location.search.substr(1);

      if (!__.IsNullOrUndefinedOrEmpty(search)) {
        // split the query parameters by the '&' character
        const filters = search.split('&');
        const parsedFilters = this.getParsedFiltersFromQueryParams(filters);

        if (parsedFilters.length > 0) {
          this.setSearchFilter(parsedFilters);
          this.setFilters(parsedFilters);
        }
      }
    }
  }

  /**
   * Calculates the number of elements that are selected in a formarray that contains formgroups for checkboxes.
   * @param formControlName Name of the form form array in the filtersForm.
   */
  getSelectedItems(formControlName: string, items: string[]): string[] {
    const control = this.filtersForm.get(formControlName).value;

    if (!__.IsNullOrUndefined(control)) {
      for (const key in control) {
        if (control.hasOwnProperty(key)) {
          const element = control[key];
          if (!__.IsNullOrUndefined(element.value) || element.value === true) {
            if (items.indexOf(element.title) === -1) {
              items.push(element.title);
            }
          }
          if (element.value === false) {
            const slicedArray = items.filter(x => x !== element.title);
            items = slicedArray;
          }
        }
      }
    }

    return items;
  }

  /**
   * Creates the text for a range (slider) for the form control with the given name if it exists
   * @param formControlName The name of the range formcontrol.
   */
  setTextForRange(formControlName: string): string {
    const control = this.filtersForm.get(formControlName).value;

    if (!this.IsNullOrUndefined(control)) {
      if (!__.IsNullOrUndefinedOrEmpty(control[0]) && __.IsNullOrUndefinedOrEmpty(control[1])) {
        return `from ${control[0]}`;
      }
      if (__.IsNullOrUndefinedOrEmpty(control[0]) && !__.IsNullOrUndefinedOrEmpty(control[1])) {
        return `up to ${control[1]}`;
      }
      if (!__.IsNullOrUndefinedOrEmpty(control[0]) && !__.IsNullOrUndefinedOrEmpty(control[1])) {
        return control[0] + ' – ' + control[1];
      }
    }
    return null;
  }

  /**
   * Creates the text for a range (slider) for the form control with the given name if it exists
   * @param formControlName The name of the range formcontrol.
   */
  setTextForCurrencyRange(formControlName: string): string {
    const control = this.filtersForm.get(formControlName).value;

    const formattedValues = [control[0], control[1]].map(q => {
      if (!__.IsNullOrUndefined(q)) {
        return this.currencyPipe.transform(q, 'EUR', 'symbol')
      } else {
        return '';
      }
    })

    if (!this.IsNullOrUndefined(formattedValues) && formattedValues.length > 0) {
      if (!__.IsNullOrUndefinedOrEmpty(formattedValues[0]) && __.IsNullOrUndefinedOrEmpty(formattedValues[1])) {
        return `from ${formattedValues[0]}`;
      }
      if (__.IsNullOrUndefinedOrEmpty(formattedValues[0]) && !__.IsNullOrUndefinedOrEmpty(formattedValues[1])) {
        return `up to ${formattedValues[1]}`;
      }
      if (!__.IsNullOrUndefinedOrEmpty(formattedValues[0]) && !__.IsNullOrUndefinedOrEmpty(formattedValues[1])) {
        return formattedValues[0] + ' – ' + formattedValues[1];
      }
    }

    return null;
  }

  /**
   * Sets the format of the text for a datepicker control in the filters form and returns the formatted text.
   * The default format is 'dd.MM.y'.
   * @param formControlName The name of the formcontrol for which the date text should be formatted.
   */
  setFormatForDateText(formControlName: string, format: string = 'DD.MM.YYYY'): string {
    const control = this.filtersForm.get(formControlName).value as Date;

    if (!this.IsNullOrUndefined(control)) {
      const formattedDate = moment(control).format(format);
      return formattedDate;
    }
    return '';
  }

  /**
   * Sets the text for a date range. If there is only the start value set the text will be 'from date' e.g. 'from 10/2019'.
   * If there is only the end value set the text will be 'to date'. If both value are given the text is 'dateStart – dateEnd'.
   * @param dateStartText The text for the start date.
   * @param dateEndText The text for the end date.
   */
  setDateRangeText(dateStartText: string, dateEndText: string): string {
    if (!__.IsNullOrUndefinedOrEmpty(dateStartText) && __.IsNullOrUndefinedOrEmpty(dateEndText)) {
      return `from ${dateStartText}`;
    }
    if (__.IsNullOrUndefinedOrEmpty(dateStartText) && !__.IsNullOrUndefinedOrEmpty(dateEndText)) {
      return `to ${dateEndText}`;
    }
    if (!__.IsNullOrUndefinedOrEmpty(dateStartText) && !__.IsNullOrUndefinedOrEmpty(dateEndText)) {
      return dateStartText + ' – ' + dateEndText;
    }
  }

  /**
   * Emits the search event with the given searchTerm that is assigned to a SearchFilter object.
   *
   * @param searchTerm The term for which the search should be executed
   */
  emitSearchEvent(searchTerm: string) {
    this.searchTermEnter$.next({
      filter: searchTerm
    });
  }

  /**
   * Resets the filter for controls in the form array that were checked for the form array with the given formControlName.
   * Just works for the checkboxes.
   *
   * @param formControl The name of the form control that is needed to reset the correct checkboxes of the correct formarray.
   */
  resetFilter(formControl: string | AbstractControl) {
    if (formControl instanceof AbstractControl) {
      if (formControl instanceof UntypedFormArray) {
        for (const control of FiltersFormUtilsService.getAsFormArray(formControl).controls) {
          if (!__.IsNullOrUndefinedOrEmpty(control.get('value').value)) {
            control.get('value').setValue(false); // reset the value for the checkboxes to false to make them unchecked
          }
        }
      }
    } else if (typeof (formControl) === 'string') {
      const foundControl = (this.filtersForm.get(formControl) as UntypedFormArray);

      if (foundControl instanceof UntypedFormArray) {
        for (const control of FiltersFormUtilsService.getAsFormArray(foundControl).controls) {
          if (!__.IsNullOrUndefinedOrEmpty(control.get('value').value)) {
            control.get('value').setValue(false); // reset the value for the checkboxes to false to make them unchecked
          }
        }
      }
      /*
      formArrayControls
      for (const control of formArrayControls) {
        if (!__.IsNullOrUndefinedOrEmpty(control.get('value').value)) {
          control.get('value').setValue(false); // reset the value for the checkboxes to false to make them unchecked
        }
      }*/
    }
    /*
    if (formControl instanceof FormArray || formControl instanceof FormGroup) {
      for (const control of FiltersFormUtilsService.getAsFormArray(formControl).controls) {
        if (!__.IsNullOrUndefinedOrEmpty(control.get('value').value)) {
          control.get('value').setValue(false); // reset the value for the checkboxes to false to make them unchecked
        }
      }
      return;
    } else if (typeof (formControl) === 'string') {
      const formArrayControls = FiltersFormUtilsService.getControls(this.filtersForm, formControl);
      for (const control of formArrayControls) {
        if (!__.IsNullOrUndefinedOrEmpty(control.get('value').value)) {
          control.get('value').setValue(false); // reset the value for the checkboxes to false to make them unchecked
        }
      }
    }*/
  }

  /**
   * Resets a single date filter.
   * @param formControl The name of the form control that is needed to reset the correct form control.
   */
  resetDateField(formControl: string | AbstractControl) {
    if (formControl instanceof AbstractControl) {
      formControl.setValue(null);
    } else if (typeof (formControl) === 'string') {
      this.filtersForm.get(formControl).setValue(null);
    }
  }

  /**
   * Resets the date range filter.
   * @param dateFieldStart Name of the first date field.
   * @param dateFieldEnd Name of the second date field.
   */
  resetDateRanges(dateFieldStart: string | AbstractControl, dateFieldEnd: string | AbstractControl) {
    this.resetDateField(dateFieldStart);
    this.resetDateField(dateFieldEnd);
  }

  /**
   * Resets a range slider for the form control with given formControlName.
   * @param formControl The name of the form control that is needed to reset the correct form control.
   */
  resetRange(formControl: string | AbstractControl, min: number, max: number) {
    if (formControl instanceof AbstractControl) {
      formControl.setValue([min, max]);
    } else if (typeof (formControl) === 'string') {
      this.filtersForm.get(formControl).setValue([min, max]);
    }
  }

  /**
   * Sets the year that the user has selected. If the element hasn't been initialized with a date
   * then init the form control with the choosen date.
   * @param normalizedYear The Moment object that contains the choosen year.
   * @param formControl The name of the formcontrol that has to be set.
   */
  setYear(normalizedYear: moment.Moment, formControl: string) {
    const launchDateControl = this.filtersForm.get(formControl);

    if (!__.IsNullOrUndefined(launchDateControl)) {
      this.filtersForm.setControl(formControl, new UntypedFormControl(normalizedYear));
    } else {
      const ctrlValue = launchDateControl.value;
      ctrlValue.year(normalizedYear.year());
      launchDateControl.setValue(ctrlValue);
    }
  }

  /**
   * Sets the month that the user has selected. If the element hasn't been initialized with a date
   * then init the form control with the choosen date.
   * @param normalizedMonth Contains the Moment object that holds the selected month.
   * @param datepicker The datepicker reference that is needed to close the datepicker after selecting the month
   * and not going further to the selection of the day.
   * @param formControl The name of the form control which date (month) needs to be set.
   */
  setMonth(normalizedMonth: moment.Moment, datepicker: MatDatepicker<moment.Moment>, formControl: string) {
    const launchDateControl = this.filtersForm.get(formControl);

    if (!__.IsNullOrUndefined(launchDateControl)) {
      this.filtersForm.setControl(formControl, new UntypedFormControl(normalizedMonth));
    } else {
      const ctrlValue = launchDateControl.value;
      ctrlValue.month(normalizedMonth.month());
      launchDateControl.setValue(ctrlValue);
    }

    datepicker.close();
  }

  /**
   * Sets the date for a datepicker form control if the given form control with the name exists.
   * @param formControl The name of the formcontrol of which the date should be set.
   * @param days Number of days which will be added or subtracted from the given days.
   * This can be used to filter ranges and possible dates. Negative value are used for subtraction.
   */
  setDate(formControl: string, days: number): Date {
    const date = this.filtersForm.get(formControl).value as moment.Moment;

    if (!__.IsNullOrUndefined(date)) {
      return new Date(date.year(), date.month() + days, date.day());
    }
  }

  /**
   * Creates an Filter object for the filters form which has a key, a title, a prefix and an indicator if the filter is a dynamic filter.
   * @param key The key that is used for filtering.
   * @param title The title of the filter.
   * @param prefix Prefix that is used for filtering when wanting to have an object like structure
   * (e.g. "objectName.propertyName")
   */
  createFilterObject(key: string, title: string, prefix: string = '') {
    return {
      key,
      title,
      prefix
    } as Filter;
  }

  /**
   * Retrieves an enum type and builds an array of Filters from the enum keys and their corresponding string values
   * to provide them for the filter view.
   * Returns an array of key value pairs for the filters that consist of the enum key as key and the enum string value as title.
   *
   * @param enumType Enum object that holds all types to get as filter array
   * and which is used for retrieving the string value of the enum key.
   * @param prefix Prefix that is used for filtering when wanting to have an object like structure
   * (e.g. "objectName.propertyName")
   */
  getEnumValuesAsFilterArray(enumType: object, prefix?: string): Filter[] {
    const filters = Object.keys(enumType)
      .filter(e => {
        return e;
      })
      .map(e => {
        return this.createFilterObject(e, enumType[e], prefix);
      });

    return filters;
  }

  /**
   * Retrieves an array of strings and builds an array of Filters and sets the value both as key
   * and as title to provide them for the filter view.
   * Returns an array of key value pairs for the filters that consist of the enum key as key and the enum string value as title.
   *
   * @param array String array that contains the values that should be mapped in the filter array.
   * @param prefix Prefix that is used for filtering when wanting to have an object like structure
   * (e.g. "objectName.propertyName").
   */
  getValuesFromArrayAsFilterArray(array: string[], prefix?: string): Filter[] {
    const filters = array.map((value: string) => {
      return {
        key: value,
        title: value,
        prefix
      } as Filter;
    });

    return filters;
  }

  /**
   * Retrieves an array of strings and an enum type which is used to build an array of filters
   * and sets the value as key and the enum string as title to provide them for the filter view.
   * Returns an array of key value pairs for the filters that consist of the enum key as key and the enum string value as title.
   * This function is useful when there are loaded additional filter values from the backend and they need to match the enum.
   *
   * @param enumType Type of the Enum object to get as filter array
   * and which is used for retrieving the string value of the enum key.
   * @param array String array that contains the values that should be mapped in the filter array.
   * @param prefix Prefix that is used for filtering when wanting to have an object like structure
   * (e.g. "objectName.propertyName").
   */
  getEnumValuesFromArrayAsFilterArray(enumType: object, array: string[], prefix?: string): Filter[] {
    const filters = array.map((value: string) => {
      return {
        key: value,
        title: enumType[value],
        prefix
      } as Filter;
    });

    return filters;
  }

  /**
   * Initializes the formgroups of a specific formarray for the number of elements in the filter array
   * that is used for the checkbox filter and pushes each element to the given formarray using formbuilder.
   *
   * @param filter Filter object array that holds the properties for filtering.
   * Each element should represent an element for one filter element in the filtersForm.
   * @param formArray Formarray to which the elements are pushed to.
   * @param fb Formbuilder instance, that is used for grouping the element before pushing it as group to the array.
   */
  initCheckboxFilterFormArray(filter: Filter[], formArray: UntypedFormArray, fb: UntypedFormBuilder) {
    for (let index = 0; index < filter.length; index++) {
      const element = filter[index];
      formArray.push(
        fb.group(
          Object.assign(element, {
            value: null
          })
        )
      );
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PROTECTED METHODS
  // -----------------------------------------------------------------------------------------------------

  protected trackLoading() {
    this.isLoading = true;

    super.addSubscription(
      (this.dataSource.database as any).isLoading$.subscribe((isLoading: boolean) => {
        this.isLoading = isLoading;
      })
    );
  }

  /**
   * Generates a certain number of elements that are used for having a loading animation in the filterable collection.
   * @param type The type of items that should be generated.
   * @param numberOfItems The number of items that should be generated. Default is 10.
   */
  protected seedInitialLoadingData(type: new () => T, numberOfItems: number = 10) {
    setTimeout(() => {
      this.dataSource.reloadData(
        Object.assign(new ReloadBehavior<T>(), {
          fromBackend: false,
          placeholders: true,
          items: this.generateItems(new type(), numberOfItems)
        } as ReloadBehavior<T>)
      );
    }, 5);
  }

  protected convertObservableToBehaviorSubject(observable: Observable<T>, initialValue: T = null): BehaviorSubject<T> {
    const subject = new BehaviorSubject(initialValue);

    super.addSubscription(
      observable.subscribe(
        (x: T) => {
          subject.next(x);
        },
        (err: any) => {
          subject.error(err);
        },
        () => {
          subject.complete();
        }
      )
    );

    return subject;
  }

  protected abstract setFiltersForm(): void;

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE METHODS
  // -----------------------------------------------------------------------------------------------------

  /**
   * Builds key value pairs from the given filters.
   * @param filters String array that contains all the filter params from the url which have the following pattern "key=value".
   */
  private getParsedFiltersFromQueryParams(filters: string[]): KeyValue<string, any>[] {
    const parsedFilters: KeyValue<string, any>[] = [];

    filters = this.checkAndParseDateRanges(filters);

    for (let index = 0; index < filters.length; index++) {
      const filter = filters[index];

      if (filter !== 'select=') {
        // split each filter into key value pairs
        const filterProperties = filter.split('=');

        const key = filterProperties[0];
        const value = filterProperties[1];

        // * seperate each value for the or filter and push it to the key value pair but with the same filter key
        if (value.includes(',')) {
          const orSplitFilters = value.split(',');

          for (const orFilter of orSplitFilters) {
            this.setFilterFormValues(orFilter, key, parsedFilters);
          }
        } else {
          this.setFilterFormValues(value, key, parsedFilters);
        }
      }
    }

    return parsedFilters;
  }

  /**
   * Does the parsing and resetting of the date range filters.
   * The date range filters are two form controls which should be treated as one filter to support the filtering via AutoQueryable.
   * Filters the date ranges from all the filters, splits them by the operators '<=' for the end and '>=' for the start value.
   * Appends the suffix of the 'Start' or 'End' control and sets the value for the right control.
   * NOTE: This controls always need to have like the suffix "Start" or "End" to make the resetting of the filter work.
   * @param filters All filter strings that were parsed from the URL. Returns a sub array of filter values not containing the date ranges,
   * because the were already evaluated.
   */
  private checkAndParseDateRanges(filters: string[]): string[] {
    // just filterr the form controls that are date ranges from all active filters
    const dateRange = filters.filter(filter => {
      const decoded = decodeURIComponent(filter);

      const lowerThanIndex = decoded.indexOf('<=');

      if (lowerThanIndex > -1) {
        return moment(decoded.substring(lowerThanIndex + 2), moment.ISO_8601, true).isValid();
      } else {
        const greaterThanIndex = decoded.indexOf('>=');

        if (greaterThanIndex > -1) {
          return moment(decoded.substring(greaterThanIndex + 2), moment.ISO_8601, true).isValid();
        }
        return false;
      }
    });

    // do this for all date range filters
    for (let index = 0; index < dateRange.length; index++) {
      const element = dateRange[index];
      // decode the element (remove special characters) to make string parsing for the date range possible
      const decodedElement = decodeURIComponent(element);
      let dateRangeProperties: string[] = [];

      // split the date range element by the right characters
      if (decodedElement.includes('<=')) {
        // end
        dateRangeProperties = decodedElement.split('<=');
      }
      if (decodedElement.includes('>=')) {
        // start
        dateRangeProperties = decodedElement.split('>=');
      }

      const key = dateRangeProperties[0];
      const value = dateRangeProperties[1];

      const controls = this.filtersForm.controls;
      let dateControlName = '';

      if (index % 2 === 0) {
        // even number --> is first element of date range and means that it is the start element
        dateControlName = key + 'Start';
      } else if (index % 2 === 1) {
        // odd number --> is second element of date range and means that it is the end element
        dateControlName = key + 'End';
      }

      if (controls.hasOwnProperty(dateControlName)) {
        const control = this.filtersForm.get(dateControlName);
        control.setValue(value);
      }
    }

    // remove the already set date range filters from the rest of the filters
    filters = filters.filter(x => !dateRange.includes(x));

    return filters;
  }


  /**
   * Sets the value for the searchFilterFormControl if it is contained in the parsedFilters key value pair.
   * The search filter has to be set individually if it isn't part of the filtersForm.
   * If that's the case, it is the value of the searchFilterFormControl.
   * @param parsedFilters Contains all parsed filters from the url (also should contain the search filter).
   */
  private setSearchFilter(parsedFilters: KeyValue<string, any>[]) {
    if (!__.IsNullOrUndefined(this.searchFilterFormControl)) {
      // *NOTE: search filter should always be the first element,...
      // *...but if it isn't then search for the occurrence in the parsedFilters key value pair
      if (parsedFilters[0].key === 'filter') {
        this.searchFilterFormControl.setValue(parsedFilters[0].value);
      } else {
        for (const filter of parsedFilters) {
          if (filter.key === 'filter') {
            this.searchFilterFormControl.setValue(filter.value);
          }
        }
      }
    }
  }

  /**
   * Sets the filters for the filtersForm.
   * Differentiates between exact and approximate filters.
   * @param parsedFilters Contains all parsed filters from the url (also should contain the search filter).
   */
  private setFilters(parsedFilters: KeyValue<string, any>[]) {
    for (const field in this.filtersForm.controls) {
      if (this.filtersForm.controls.hasOwnProperty(field)) {
        // Only match exact
        const foundFiltersExact = parsedFilters.filter(q => q.key === field);

        const control = this.filtersForm.get(field);

        if (foundFiltersExact.length > 0) {
          this.setExactFilters(control, foundFiltersExact);
        } else {
          // Match starting
          const foundFiltersApproximately = parsedFilters.filter(q => q.key.startsWith(field));
          this.setFiltersApproximately(control, foundFiltersApproximately);
        }
      }
    }
  }

  /**
   * ets the filter values if the key approximately matches the field names of the controls.
   * Sets the range sliders for the filters.
   * @param control Control in which the value needs to be set.
   * @param foundFiltersApproximately Filtered key value pairs which approximately match the field name.
   */
  private setFiltersApproximately(control: AbstractControl, foundFiltersApproximately: KeyValue<string, any>[]) {
    if (foundFiltersApproximately.length === 2) {
      // Range slider
      const from = foundFiltersApproximately[0];
      const to = foundFiltersApproximately[1];

      if (!__.IsNullOrUndefined(from) && !__.IsNullOrUndefined(to)) {
        control.setValue([from.value, to.value]);
      } else if (__.IsNullOrUndefined(from) && !__.IsNullOrUndefined(to)) {
        control.setValue([0, to.value]);
      } else if (!__.IsNullOrUndefined(from) && __.IsNullOrUndefined(to)) {
        control.setValue([from.value, 0]);
      }
    }
  }

  /**
   * Sets the filter values if the key exactly matches the field names of the controls.
   * Sets primitive types and checkboxes.
   * @param control Control in which the value needs to be set.
   * @param foundFiltersExact Filtered key value pairs which exactly match the field name.
   */
  private setExactFilters(control: AbstractControl, foundFiltersExact: KeyValue<string, any>[]) {
    if (control instanceof UntypedFormControl) {
      // Primitive such as date or boolean
      if (!__.IsNullOrUndefinedOrEmpty(foundFiltersExact[0].value)) {
        control.setValue(foundFiltersExact[0].value);
      }
    } else if (control instanceof UntypedFormArray) {
      // Arrays such as multiple checkboxes
      // set field value
      const formArray: UntypedFormArray = control as UntypedFormArray;

      for (const formControl in formArray.controls) {
        if (formArray.controls.hasOwnProperty(formControl)) {
          const elementControl = formArray.get(formControl);

          const keyControl = elementControl.get('key');
          const valueControl = elementControl.get('value');

          if (foundFiltersExact.find(q => q.value === keyControl.value)) {
            valueControl.setValue(true);
          }
          // get title for each form array entry and match with value. If yes -> set to true
          // valueControl.setValue(value)
        }
      }
    }
  }

  /**
   * Sets the form control value as the correct type and adds the key and the corresponding value to the parsedFilters key value pair.
   * @param value Contains the value of the filter.
   * @param key Is the filter key.
   * @param parsedFilters Key value pair to which the filters are added to.
   */
  private setFilterFormValues(value: string, key: string, parsedFilters: KeyValue<string, any>[]) {
    let formControlValue: any = null;

    if (!__.IsNullOrUndefinedOrEmpty(value)) {
      if (FilterUtilsService.isBoolean(value)) {
        formControlValue = FilterUtilsService.parseBoolean(value);
      } else if (FilterUtilsService.isNumber(value)) {
        formControlValue = FilterUtilsService.parseNumber(value);
      } else {
        formControlValue = decodeURIComponent(value);
      }

      parsedFilters.push({ key, value: formControlValue });
    }
  }

  private generateItems(type: any, number: number): T[] {
    let iterator = number;
    const objects = [];
    while (iterator >= 0) {
      objects.push(type);
      iterator = iterator - 1;
    }
    return objects;
  }
}
