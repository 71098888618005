import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { __ } from '@app/shared/functions/object.functions';

import { GenericMenuItem } from './generic-menu-item.model';

@Component({
  selector: 'kb-generic-menu',
  templateUrl: './generic-menu.component.html',
  styleUrls: ['./generic-menu.component.scss']
})
export class GenericMenuComponent {

  @Input() item: any;

  @Input() menuItems: GenericMenuItem[] = [];

  @Input() disabled: boolean = false;

  @Input() icon: string = 'ellipsis';

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onItemClicked: EventEmitter<GenericMenuItem> = new EventEmitter<GenericMenuItem>();

  @Output() onMenuClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private router: Router
  ) { }

  /**
   * The callback when a menu item was clicked. Executes up to three handling actions:
   * 1. Emits the clicked menu item
   * 2. Navigates to a provided route
   * 3. Opens a dialog using the provided open dialog method
   *
   */
  itemClick(menuItem: GenericMenuItem): void {
    if (!__.IsNullOrUndefined(menuItem.actionType)) {
      this.onItemClicked.emit(menuItem);
    }
    if (!__.IsNullOrUndefined(menuItem.routerLink)) {
      this.router.navigate([menuItem.routerLink]);
    }
    if (!__.IsNullOrUndefined(menuItem.execute)) {

      if (!__.IsNullOrUndefined(this.item)) {
        menuItem.execute.bind(menuItem.context)(this.item);
      } else {
        menuItem.execute.call(
          __.IsNullOrUndefined(menuItem.context) ? this : menuItem.context
        );
      }
    }
  }

  checkDisabled(menuItem: GenericMenuItem) {
    if (typeof (menuItem.disabled) === typeof (true)) {
      return menuItem.disabled;
    }
    if (this.isFunction(menuItem.disabled)) {
      return menuItem.disabled.call(__.IsNullOrUndefined(menuItem.context) ? this : menuItem.context, this.item);
    }
    return true;
  }

  checkIsVisible(menuItem: GenericMenuItem) {
    if (typeof (menuItem.visible) === typeof (true)) {
      return menuItem.visible;
    }
    if (this.isFunction(menuItem.visible)) {
      return menuItem.visible.call(__.IsNullOrUndefined(menuItem.context) ? this : menuItem.context, this.item);
    }
    return false;
  }

  anyItemsVisible(): boolean {
    return this.menuItems.some(a => {
      if (!__.IsNullOrUndefined(a.visible) && this.isFunction(a.visible)) {
        return a.visible(a) === true;
      } else if (!__.IsNullOrUndefined(a.visible)) {
        return a.visible;
      }
      return false;
    });
  }

  private isFunction(functionToCheck: any) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
  }
}






