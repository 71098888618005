import { __ } from '@app/shared/functions/object.functions';
import { Observable, Subject } from 'rxjs';

import { FileService } from '../file.service';
import { ProgressEvent } from '../progress/progress.component';
import { ExistingImage, FileBase, NewImage } from './File';
import { FileState } from './FileState.enum';
import { FileType } from './FileType.enum';
import { LoadingState } from './LoadingState.enum';

export abstract class FileContainerBase {
  private _original: FileBase;
  get original(): FileBase {
    return this._original;
  }
  set original(file: FileBase) {
    this._original = file;
    this._state = file.state;
    this.id = file.id;
    file.parent = this;
    if (__.IsNullOrUndefinedOrEmpty(file.kind)) {
      file.kind = 'Original';
    }
  }

  private _loadingProgress: ProgressEvent;
  private _loadingProgress$: Subject<ProgressEvent> = new Subject<ProgressEvent>();
  loadingProgress$: Observable<ProgressEvent> = this._loadingProgress$.asObservable();
  get loadingProgress(): ProgressEvent {
    return this._loadingProgress;
  }
  set loadingProgress(progress: ProgressEvent) {
    this._loadingProgress = progress;
    this._loadingProgress$.next(progress);
  }

  id: string;

  protected _state: FileState;
  get state(): FileState {
    return this._state;
  }

  protected _loadingState: LoadingState;
  protected _loadingState$: Subject<LoadingState> = new Subject<LoadingState>();
  get loadingState$(): Observable<LoadingState> {
    return this._loadingState$.asObservable();
  }
  set loadingState(loadingState: LoadingState) {
    this._loadingState = loadingState;
    this._loadingState$.next(loadingState);
  }

  abstract type: FileType;

  constructor(id: string = '') {
    if (__.IsNullOrUndefinedOrEmpty(id)) {
      this.id = FileService.NewGuid();
    }
  }

  abstract getFiles(): FileBase[];

  setLoadingProgressForFile(file: FileBase, progress: number): void {
    const newFiles = this.getFiles().filter(q => q.state === FileState.New);

    if (newFiles.length === 0) {
      this.loadingState = LoadingState.Completed;
      this.loadingProgress = new ProgressEvent(100, LoadingState.Completed);
      return;
    }

    const fileIndex = newFiles.findIndex(q => q.id === file.id);

    if (fileIndex > -1) {
      const areaSize = 1 / newFiles.length;
      this.loadingProgress = new ProgressEvent(Math.round((fileIndex + 1) * areaSize * progress), LoadingState.Completed);
    }
  }
}

export class ImageContainer extends FileContainerBase {
  type = FileType.TrackingLabel;

  private _thumbnail: NewImage | ExistingImage;
  get thumbnail(): NewImage | ExistingImage {
    return this._thumbnail;
  }
  set thumbnail(file: NewImage | ExistingImage) {
    this._thumbnail = file;
    if (!__.IsNullOrUndefined(file)) {
      if (this.original.state === FileState.Existing) {
        this._state = file.state;
      }
      file.parent = this;
      if (__.IsNullOrUndefinedOrEmpty(file.kind)) {
        file.kind = 'Thumbnail';
      }
    }
  }

  constructor(id: string = '') {
    super(id);
  }

  getFiles(): FileBase[] {
    return [this.original, this.thumbnail].filter(q => !__.IsNullOrUndefined(q));
  }
}

export class FileContainer extends FileContainerBase {
  type = FileType.TrackingLabelReturn;

  constructor(id: string = '') {
    super(id);
  }

  getFiles(): FileBase[] {
    return [this.original].filter(q => !__.IsNullOrUndefined(q));
  }
}
