import { __ } from '@app/shared/functions/object.functions';

export class FilterUtilsService {
    // -----------------------------------------------------------------------------------------------------
    // @ STATIC METHODS
    // -----------------------------------------------------------------------------------------------------

    static isObject(obj: any) {
        return !__.IsNullOrUndefined(obj) && !__.IsNullOrUndefined(obj.constructor) && obj.constructor.name === 'Object';
    }

    static isBoolean(value: any): boolean {
        return (value === 'true') || (value === 'false');
    }

    static isNumber(value: any): boolean {
        return !isNaN(value);
    }

    // replace by real check
    static parseBoolean(value: any): boolean {
        if (value === 'true') {
            return true;
        } else if (value === 'false') {
            return false;
        } else {
            return null;
        }
    }

    // replace by real check
    static parseNumber(value: any): number {
        return parseInt(value, 10);
    }
}
