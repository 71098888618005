import { NgModule } from '@angular/core';

import { ProgressComponent } from './progress.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedModule } from '../../../shared.module';

@NgModule({
  imports: [SharedModule, MatProgressBarModule, MatProgressSpinnerModule],
  declarations: [ProgressComponent],
  exports: [ProgressComponent]
})
export class ProgressModule { }
