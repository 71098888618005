import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { JadFormFieldsCheckboxComponent } from './form-fields-checkbox.component';

@NgModule({
  imports: [
    CommonModule,

    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule
  ],
  declarations: [JadFormFieldsCheckboxComponent],
  exports: [JadFormFieldsCheckboxComponent]
})
export class JadFormFieldsCheckboxModule { }
