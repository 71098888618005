import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { __ } from '@app/shared/functions/object.functions';
import { Observable, Subscription } from 'rxjs';

import { FileContainerBase } from '../models/FileContainer';
import { LoadingState } from '../models/LoadingState.enum';

export class ProgressEvent {
  constructor(public progress: number, public loadingState: LoadingState) { }
}

@Component({
  selector: 'kb-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent extends BaseComponent implements OnInit {

  @HostBinding('style.display') display: string = 'block';

  mode: ProgressBarMode = 'buffer';

  color: 'primary';

  state: 'Loading' | 'Completed' | 'Erroneous' = 'Loading';

  value = 0;

  progressBarIsVisible = false;

  progressSubscription: Subscription;

  @Input() initialLoadingState: LoadingState = LoadingState.Querying;

  private _progress$: Observable<ProgressEvent>;
  @Input()
  get progress$(): Observable<ProgressEvent> {
    return this._progress$;
  }
  set progress$(value: Observable<ProgressEvent>) {
    this._progress$ = value;

    if (!__.IsNullOrUndefined(value)) {
      this.trackProgress();
    }
  }

  private _file: FileContainerBase;

  @Input()
  get file(): FileContainerBase {
    return this._file;
  }
  set file(value: FileContainerBase) {
    this._file = value;

    if (!__.IsNullOrUndefined(value)) {
      this.trackProgress();
    }
  }

  constructor() {
    super();
  }

  ngOnInit() {
    if (!__.IsNullOrUndefinedOrEmpty(this.progress$)) {
      this.trackProgress();
    }
  }

  trackProgress(): void {
    if (!__.IsNullOrUndefined(this.progressSubscription)) {
      this.progressSubscription.unsubscribe();
      this.progressSubscription = null;
    }

    if (!__.IsNullOrUndefined(this.file)) {
      this._progress$ = this.file.loadingProgress$;
    }

    this.progressSubscription = this.progress$.subscribe({
      next: (event: ProgressEvent) => {
        this.value = event.progress;

        if (event.loadingState !== LoadingState.Initial) {
          this.progressBarIsVisible = true;
        }
        if (__.IsNullOrUndefined(event)) {
          this.mode = 'indeterminate';
          return;
        }
        if (event.progress <= 1) {
          this.mode = 'buffer';
          this.state = LoadingState.Loading;
        } else if (event.progress < 100) {
          this.mode = 'determinate';
          this.state = LoadingState.Loading;
        } else if (event.loadingState === LoadingState.Loading || event.loadingState === LoadingState.Querying) {
          this.mode = 'query';
          this.state = LoadingState.Loading;
        } else if (event.loadingState === LoadingState.Erroneous) {
          this.mode = 'determinate';
          this.state = LoadingState.Erroneous;
        } else {
          this.mode = 'determinate';
          this.state = LoadingState.Completed;
          setTimeout(() => {
            this.progressBarIsVisible = false;
          }, 1000);
        }
      }
    });

    super.addSubscription(this.progressSubscription);
  }
}
