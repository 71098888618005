import { BaseService } from '@app/shared/base/services';
import { __ } from '@app/shared/functions/object.functions';
import { DatabaseFile } from '@app/shared/models/classes/File';
import { Image } from '@app/shared/models/classes/Image';
import { Observable, of, Subject, Subscriber } from 'rxjs';
import { last } from 'rxjs/operators';

import { ExistingFile, ExistingImage, ExistingImageWithVariations, NewFile, NewImage } from './models/File';
import { FileContainer, FileContainerBase, ImageContainer } from './models/FileContainer';
import { LoadingState } from './models/LoadingState.enum';

/**
 * This service provides useful functionalities regarding e.g. file loading and previewing as well
 * as common file functionalities
 *
 * @export
 * @extends {BaseService}
 */
export class FileService extends BaseService {

  private _shouldTriggerFileDialog$: Subject<boolean> = new Subject<boolean>();

  // tslint:disable-next-line:member-ordering
  public shouldTriggerFileDialog$: Observable<boolean> = this._shouldTriggerFileDialog$.asObservable();

  /**
   * Derives the content type by the provided mime type. Calculates for Images,
   * Audios, Videos, Word files, Excel files, Powerpoint files, Archives, Pdf files and
   * text files.
   *
   * @param mimeType The mime type from which the content type should be dervied
   */
  public static getContentTypeByMimeType(mimeType: string): string {
    switch (mimeType) {
      case 'image/bmp':
      case 'image/gif':
      case 'image/jpeg':
      case 'image/png':
      case 'image/tiff':
      case 'image/svg+xml':
        return 'Image';
      case 'video/x-msvideo':
      case 'video/mpeg':
      case 'video/webm':
      case 'video/mp4':
        return 'Video';
      case 'audio/wav':
      case 'audio/mpeg':
        return 'Audio';
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument. wordprocessingml.document':
        return 'Word';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.ms-excel':
        return 'Excel';
      case 'application/zip':
      case 'application/x-7z-compressed':
      case 'application/x-rar-compressed':
        return 'Archive';
      case 'application/ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return 'Powerpoint';
      case 'application/pdf':
        return 'Pdf';
      case 'application/text':
      case 'application/rtf':
        return 'Text';
      default:
        console.log('Unkown mime type detected');
        return 'Unkown';
    }
  }

  /**
   * Transforms user selected files (FileList) to compatible files (FileBase)
   *
   * @param fileList The user selected files
   * @returns The transformed files
   */
  public static transformFiles(fileList: File | FileList | (DatabaseFile | Image)[]): FileContainerBase[] {
    // derive existingfile or image from filename
    const fileContainers: FileContainerBase[] = [];

    if (fileList instanceof File) {
      const file: File = fileList;

      const contentType = FileService.getContentTypeByMimeType(FileService.getMimeTypeByFileName(file.name));
      let container: FileContainerBase = null;
      switch (contentType) {
        case 'Image':
          container = Object.assign(new ImageContainer(), {
            original: new NewImage(file),
            thumbnail: null
          });
          break;
        default:
          container = Object.assign(new FileContainer(), {
            original: new NewFile(file),
            thumbnail: null
          });
          break;
      }
      fileContainers.push(container);
    }

    if (fileList instanceof FileList) {
      for (let index = 0; index < fileList.length; index++) {
        const file: File = fileList[index];

        const contentType = FileService.getContentTypeByMimeType(FileService.getMimeTypeByFileName(file.name));
        let container: FileContainerBase = null;
        switch (contentType) {
          case 'Image':
            container = Object.assign(new ImageContainer(), {
              original: new NewImage(file),
              thumbnail: null
            });
            break;
          default:
            container = Object.assign(new FileContainer(), {
              original: new NewFile(file),
              thumbnail: null
            });
            break;
        }
        fileContainers.push(container);
      }
    }

    if (fileList instanceof Array) {
      for (let index = 0; index < fileList.length; index++) {
        const file: DatabaseFile = fileList[index] as DatabaseFile;

        let fileContainer: FileContainerBase;

        fileContainer = Object.assign(new FileContainer(), {
          original: new ExistingFile(file.id, file.name, file.size, file.contentType)
        });

        fileContainers.push(fileContainer);
      }
    }

    return fileContainers;
  }

  /**
   * Derives the mime type by the provided file name by checking the file extension.
   * Supports popular Video, Audio, Archive types and also specific file types like
   * pdf, word, excel, powerpoint and text files
   */
  public static getMimeTypeByFileName(fileName: string): string {
    const fileExtension = FileService.GetFileExtension(fileName);

    switch (fileExtension) {
      case 'AVI':
        return 'video/x-msvideo';
      case 'BMP':
        return 'image/bmp';
      case 'GIF':
        return 'image/gif';
      case 'JPEG':
      case 'JPG':
        return 'image/jpeg';
      case 'MP3':
        return 'audio/mpeg';
      case 'MPEG':
        return 'video/mpeg';
      case 'PNG':
        return 'image/png';
      case 'PDF':
        return 'application/pdf';
      case 'SVG':
        return 'image/svg+xml';
      case 'TIF':
      case 'TIFF':
        return 'image/tiff';
      case 'WAV':
        return 'audio/wav';
      case 'WEBM':
        return 'video/webm';
      case 'ZIP':
        return 'application/zip';
      case '7Z':
        return 'application/x-7z-compressed';
      case 'XLSX':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case 'XLS':
        return 'application/vnd.ms-excel';
      case 'RAR':
        return 'application/x-rar-compressed';
      case 'PPTX':
        return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
      case 'PPT':
        return 'application/ms-powerpoint';
      case 'RTF':
        return 'application/rtf';
      case 'MP4':
        return 'video/mp4';
      case 'DOCX':
        return 'application/vnd.openxmlformats-officedocument. wordprocessingml.document';
      case 'DOC':
        return 'application/msword';
      case 'TXT':
        return 'application/text';
      default:
        console.log('Unkown file extension detected');
        return 'unknown';
    }
  }

  /**
   * "C# GUIDs are guaranteed to be unique. This solution is very likely to be unique.
   * This generated key is great to use as a temporary key, not as a real GUID."
   *
   * Stolen from https://stackoverflow.com/questions/26501688/a-typescript-guid-class
   *
   * @return Returns a new, very likely to be unique, GUID
   *
   * @example let guid = Guid.NewGuid();
   */
  static NewGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c: any) => {
      // tslint:disable-next-line:no-bitwise
      const r = (Math.random() * 16) | 0;
      // tslint:disable-next-line:no-bitwise
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  static CleanFileName(fileName: string): string {
    return fileName
      .replace(' ', '')
      .replace('&', '')
      .replace('%', '')
      .replace(';', '')
      .replace('?', '');
  }

  static GetFileExtension(fileName: string): string {
    if (__.IsNullOrUndefined(fileName)) {
      return '';
    } else {
      return fileName.substring(fileName.lastIndexOf('.') + 1).toUpperCase();
    }
  }

  /**
   * Creates an instance of FileService.
   * @param maximumFileSize The maximum allowed file sized in bytes
   * @param allowedFileTypes The allowed file types, e.g. png, jpg, zip
   */
  constructor(
    protected maximumFileSize: number = 1024 * 1024 * 50, // 50 MB,
    protected allowedFileTypes: string[] = []
  ) {
    super();
  }

  isFileTypeAllowed(type: string): boolean {
    return this.allowedFileTypes.includes(type.toLowerCase());
  }

  isFileSizeAllowed(size: number): boolean {
    return size <= this.maximumFileSize;
  }

  /**
   * Loads the blobs of the provided files wit the FileReader to preview them e.g. in an image tag.
   * This method is a wrapper for internal implementation readFiles.
   * Since JavaScript is reference based, you don't need to use the value that is being emitted.
   *
   * @param files The files which should be previewd
   * @returns An observable emitting once when every file has ben read
   */
  previewFiles(files: NewImage[] | NewFile[]): Observable<NewImage[] | NewFile[]> {
    return this.readFiles(files);
  }

  triggerFileDialog(): void {
    this._shouldTriggerFileDialog$.next(true);
  }

  /**
   * Reads provided files with the FileReader into the source property
   *
   * @param files The files whose sources should be read
   * @returns An observable emitting once when every file has ben read
   */
  private readFiles(files: NewImage[] | NewFile[]): Observable<NewImage[] | NewFile[]> {
    if (files.length === 0) {
      return of(files);
    }
    const observable = new Observable((observer: Subscriber<NewImage[] | NewFile[]>) => {
      this.readFilesInner(files, observer, 0);
    });
    return observable.pipe(last());
  }

  /**
   * Recursive method for reading files with the FileReader into the source property of the provided files.
   * Finishes execution when there are no more files left to load.
   *
   * @param files The files which should be loaded
   * @param observer The observer to notify of loaded files
   * @param [index=0] The index of the current loaded file
   */
  private readFilesInner(
    files: NewImage[] | NewFile[],
    observer: Subscriber<NewImage[] | NewFile[]>,
    index: number = 0
  ) {
    const fileReader = new FileReader();
    const file = files[index];

    if (!__.IsNullOrUndefined(file)) {
      file.loadingProgress = 1;
      file.loadingState = LoadingState.Loading;

      fileReader.onload = (ev: ProgressEvent) => {
        file.source = fileReader.result;
        file.dataUrl = URL.createObjectURL(files[index].file);
        file.loadingProgress = 100;
        file.loadingState = LoadingState.Completed;

        this.readFilesInner(files, observer, index + 1);
      };
      fileReader.onerror = () => {
        file.loadingState = LoadingState.Erroneous;
        this.readFilesInner(files, observer, index + 1);
      };

      // TODO: Put into worker thread?
      fileReader.readAsDataURL(file.file);
    } else {
      observer.next(files);
      observer.complete();
    }
  }
}
