import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { Directive, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { __ } from '@app/shared/functions/object.functions';
import { BaseModel } from '@app/shared/models/classes/BaseModel';
import { finalize, mergeMap, takeUntil } from 'rxjs/operators';

import { ReloadBehavior } from '../models/reload-behaviour';
import { BaseComponent } from './base-component';
import { BaseDataSource } from './base-datasource.component';

/**
 * This component provides properties and functions that are needed for loading collections (list/arrays/...) async.
 * Things like pagination, sorting, BaseDataSource and item manipulation are provided.
 */
@Directive()
export abstract class BaseCollectionComponent<T extends BaseModel> extends BaseComponent implements OnInit {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  removeIsLoading: boolean = false;

  selection = new SelectionModel<T>(true, []);

  // -----------------------------------------------------------------------------------------------------
  // @ VIEW CHILD & CHILDREN VARIABLES
  // -----------------------------------------------------------------------------------------------------
  
  @ViewChild(MatSort, { static: true })
  get sort(): MatSort {
    return this._sort;
  }
  set sort(sort: MatSort) {
    this._sort = sort;
    if (!__.IsNullOrUndefined(sort)) {
      this._sort.sortChange.subscribe((data: any) => {
      });
    }
  }

  @ViewChild(MatPaginator, { static: true })
  get paginator(): MatPaginator {
    return this._paginator;
  }
  set paginator(paginator: MatPaginator) {
    this._paginator = paginator;
    if (!__.IsNullOrUndefined(paginator)) {
      this._paginator.page.subscribe((data: any) => {
      });
    }
  }
  
  // -----------------------------------------------------------------------------------------------------
  // @ OUTPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  @Output() dataChanged: EventEmitter<T[]> = new EventEmitter<T[]>(true);

  @Output() rowClicked: EventEmitter<T> = new EventEmitter<T>();

  @Output() selectedChanged: EventEmitter<T[]> = new EventEmitter<T[]>();

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  @Input() shouldNavigate = true;

  private _dataSource: BaseDataSource<T>;
  @Input()
  get dataSource(): BaseDataSource<T> {
    return this._dataSource;
  }
  set dataSource(value: BaseDataSource<T>) {
    this._dataSource = value;

    if (!__.IsNullOrUndefined(value)) {
      super.addSubscription(
        this.dataSource.database.dataChanged$.subscribe((items: T[]) => {
          this.dataChanged.emit(items);
        })
      );
    }
  }

  private _preSelected: T[] = [];
  @Input()
  get preSelected(): T[] {
    return this._preSelected;
  }
  set preSelected(items: T[]) {
    this._preSelected = items;
    if (!__.IsNullOrUndefined(items)) {
      this.selection.select(...items);
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  private _paginator: MatPaginator;

  private _sort: MatSort;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(protected injector: Injector) {
    super();

    super.addSubscription(
      this.selection.changed.subscribe((data: SelectionChange<T>) => {
        this.selectedChanged.emit(data.source.selected);
      })
    );
  }
  
  // -----------------------------------------------------------------------------------------------------
  // @ ABSTRACT METHODS
  // -----------------------------------------------------------------------------------------------------

  /**
   * Implement the ngOnInit hook to set the datasource
   * ```
   * this.dataSource = new BaseDataSource<User>(
   * new BaseDatabase<User>(
   * `users`,
   * (q: T) => q.id,
   * this.injector,
   * this.paginator,
   * this.sort
   * )
   * );
   * ```
   *
   * abstract
   * BaseStepComponent
   */
  abstract ngOnInit(): any;

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  /**
   * Determines if a passed value is in the selection model based on the id
   * @param row The value taht should be checked
   */
  isSelected(item: T): boolean {
    return this.selection.selected?.findIndex(q => q?.id === item?.id) > -1;
  }

  /**
   * Checks whether the number of selected elements matches the total number of rows.
   * Optionally, a disabled selector can be provided to exclude disabled items.
   *
   * @param [disabledSelector] The property selector that excludes disabled items
   * BaseTableComponent
   */
  isAllSelected(disabledSelector?: (row: T) => any): boolean {
    const numSelected = this.selection.selected.length;
    let numRows = 0;
    if (__.IsNullOrUndefined(disabledSelector)) {
      if (!__.IsNullOrUndefined(this.dataSource.database.currentSet)) {
        numRows = this.dataSource.database.currentSet.length;
      }
    } else {
      numRows =
        this.dataSource.database.currentSet.length -
        this.dataSource.database.currentSet.filter(q => disabledSelector(q)).length;
    }
    return numSelected === numRows;
  }

  /**
   * Selects all rows if they are not all selected and otherwise clears the selection.
   * Optionally, a disabled selector can be provided to exclude disabled items.
   *
   * @param [disabledSelector] The property selector that excludes disabled items
   * BaseTableComponent
   */
  masterToggle(disabledSelector?: (row: T) => any) {
    if (this.isAllSelected(disabledSelector)) {
      this.selection.clear();
    } else {
      this.dataSource.database.currentSet.forEach(row => {
        if (__.IsNullOrUndefined(disabledSelector)) {
          this.selection.select(row);
        } else {
          if (!disabledSelector(row)) {
            this.selection.select(row);
          }
        }
      });
    }
  }

  toggleSelection(row: T): void {
    const index = this.selection.selected.findIndex(q => q.id === row.id);
    if (index > -1) {
      this.selection.deselect(this.selection.selected[index]);
    } else {
      this.selection.select(row);
    }
  }
  /**
   * Either removes the provided set of items or the current selection
   * from the database. Returns the data changed observable so the user
   * will be notified when the reload of the data has been completed.
   *
   * @param [items=[]] The items that should be removed
   * BaseTableComponent
   */
  removeItems(items: T[] = []): void {
    let remove = this.selection.selected;
    if (items.length > 0) {
      remove = items;
    }
    this.removeIsLoading = true;

    this.dataSource.database.removeItems(remove).pipe(
      finalize(() => {
        this.removeIsLoading = false;
      }),
      mergeMap((data: boolean) => {
        // this.toastr.success('The items were successfully removed');
        this.selection.clear();
        this.dataSource.reloadData(new ReloadBehavior<T>());
        return this.dataSource.database.dataChanged$.pipe(takeUntil(this.dataSource.database.disrupt$));
      })
    ).subscribe();
  }
}
