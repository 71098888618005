<div class="form-fields-checkbox-container">

  <!-- LABEL -->
  <div class="form-fields-checkbox-container__label"
    *ngIf="!IsNullOrUndefinedOrEmpty(label) || !IsNullOrUndefinedOrEmpty(displayName)">
    {{ !IsNullOrUndefinedOrEmpty(label) ? label : displayName }} {{ required === true ? '*' : '' }}
  </div> <!-- /form-fields-checkbox-container__label -->

  <!-- CONTENT -->
  <div class="form-fields-checkbox-container__content">
    <div class="flex -ml-3">
      <mat-checkbox [required]="required" [color]="'primary'" [formControl]="getAsFormControl(control)" [class]="formFieldClass">
        <ng-content></ng-content>
      </mat-checkbox>
    </div>
  </div> <!-- /form-fields-checkbox-container__content -->
</div> <!-- /form-fields-checkbox-container -->