import { DataSource } from '@angular/cdk/table';
import { merge, Observable } from 'rxjs';
import { mergeMap, switchMap, takeUntil } from 'rxjs/operators';

import { ReloadBehavior } from '../models/reload-behaviour';
import { BaseDatabase } from './base-database.component';
import { __ } from '@app/shared/functions/object.functions';

export class BaseDataSource<T> extends DataSource<T> {
    // -----------------------------------------------------------------------------------------------------
    // @ CONSTRUCTOR
    // -----------------------------------------------------------------------------------------------------

    constructor(
        public database: BaseDatabase<T>,
        public fetchOnSubscribe: boolean = false
    ) {
        super();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC METHODS
    // -----------------------------------------------------------------------------------------------------

    /**
     * Connects to a stream of observables, such as the paginator, the sorter,
     * the reload of the database or the filter. An update is performed
     * base on the provided reload behavior.
     *
     */
    connect(): Observable<T[]> {
        const observables: Observable<any>[] = [
            this.database.reload$
        ];
        if (!__.IsNullOrUndefined(this.database.paginator)) {
            observables.push(this.database.paginator.page);
        }
        if (!__.IsNullOrUndefined(this.database.sort)) {
            observables.push(this.database.sort.sortChange);
        }
        if (!__.IsNullOrUndefined(this.database.filterValuesChanged$)) {
            observables.push(this.database.filterValuesChanged$);
        }

        if (this.fetchOnSubscribe === true) {
            setTimeout(() => {
                this.reloadData();
            }, 100);
        }

        return merge(...observables)
            .pipe(
                switchMap((value: any) => {
                    let reloadBehavior = new ReloadBehavior<T>();
                    if (!__.IsNullOrUndefined(value) && !__.IsNullOrUndefined(value.fromBackend) && !__.IsNullOrUndefined(value.items)) {
                        reloadBehavior = value;
                    }
                    if (__.IsNullOrUndefined(value)) {
                        reloadBehavior = null;
                    }
                    return this.database.update(reloadBehavior);
                }),
            );
    }

    disconnect() { }

    /**
     * Triggers a reload of the data based on the provided reload behavior.
     * The data changed observable is returned to which the user can subscribe to
     * to be notified, when the reload was successfull.
     *
     * @param reloadBehavior The reload behavior that determines the reloading strategy
     */
    reloadData(reloadBehavior: ReloadBehavior<T> = null): Observable<T[]> {
        if (__.IsNullOrUndefined(reloadBehavior)) {
            reloadBehavior = new ReloadBehavior<T>();
        }
        this.database.reload$.next(reloadBehavior);
        return this.database.dataChanged$.pipe(takeUntil(this.database.disrupt$));
    }
}
