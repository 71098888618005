<ng-container *ngIf="progressBarIsVisible === true">
  <mat-progress-bar
    [style.opacity]="state === 'Completed' || state === 'Erroneous' ? 0 : 1"
    [ngClass]="{
      'kb-progress-bar': true,
      completed: state === 'Completed',
      erroneous: state === 'Erroneous'
    }"
    [color]="color"
    [mode]="mode"
    [value]="value"
  >
  </mat-progress-bar>
</ng-container>
