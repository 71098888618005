import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter, Observable, Subject, Subscriber } from 'rxjs';

import { BaseService } from '../base/services';
import { __ } from '../functions/object.functions';

export const INPUT_MODE_CONFIGURATION = new InjectionToken<InputModeConfiguration>('INPUT_MODE_CONFIGURATION');

export class InputModeConfiguration {
    isInput: boolean = false;

    // Define on which media queries the behavior can be activated
    onMqAlias: string[] = ['xs'];
}

@Injectable()
export class InputModeService extends BaseService {

    configuration: InputModeConfiguration;

    configuration$: Observable<InputModeConfiguration>;

    private _configuration: InputModeConfiguration;

    private _configuration$: Subject<InputModeConfiguration> = new Subject<InputModeConfiguration>();

    constructor(
        protected httpClient: HttpClient,
        private router: Router,
        @Optional() @Inject(INPUT_MODE_CONFIGURATION) configuration: InputModeConfiguration,
    ) {
        super();

        if (!__.IsNullOrUndefined(configuration)) {
            this._configuration = configuration;
            this.configuration = this._configuration;
        } else {
            this.configuration = new InputModeConfiguration();
        }

        this.configuration$ = new Observable<InputModeConfiguration>((subscriber: Subscriber<InputModeConfiguration>) => {
            if (!__.IsNullOrUndefined(this.configuration)) {
                subscriber.next(this.configuration);
            }
            super.addSubscription(
                this._configuration$.subscribe((updatedConfiguration: InputModeConfiguration) => {
                    this._configuration = updatedConfiguration;
                    this.configuration = updatedConfiguration;

                    // if (this.configuration.onMqAlias.some(q => this.mediaObserver.isActive(q))) {
                    //     subscriber.next(updatedConfiguration);
                    // }
                })
            )
        });

        super.addSubscription(
            this.router.events.pipe(
                filter(event => event instanceof NavigationStart))
                .subscribe(_ => {
                    this.setInputMode(false);
                })
        );
    }

    setConfiguration(configuration: InputModeConfiguration): void {
        this._configuration = configuration;
        this.configuration = this._configuration;
        this._configuration$.next(this.configuration);
    }

    setInputMode(isInput: boolean): void {
        if (__.IsNullOrUndefined(this._configuration)) {
            this._configuration = new InputModeConfiguration();
        }
        this._configuration.isInput = isInput;
        this.configuration = this._configuration;
        this._configuration$.next(this.configuration);
    }
}
