import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { BaseTextFormFieldComponent } from '../base/base-form-field.component';

@Component({
  selector: 'jad-form-fields-checkbox',
  templateUrl: './form-fields-checkbox.component.html',
  styleUrls: ['./form-fields-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JadFormFieldsCheckboxComponent extends BaseTextFormFieldComponent {
}
