import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
GenericMenuComponent,
} from './generic-menu.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,

    TranslateModule
  ],
  declarations: [GenericMenuComponent],
  exports: [
    GenericMenuComponent
  ]
})
export class GenericMenuModule { }
