import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { DefaultDatePipe } from './default-date.pipe';

/**
 * The pipes module exports custom pipes
 *
 * @export
 */
@NgModule({
    imports: [CommonModule],
    declarations: [DefaultDatePipe],
    providers: [DatePipe],
    exports: [DefaultDatePipe]
})
export class DefaultDatePipeModule { }
