import { __ } from '@app/shared/functions/object.functions';

import { Role } from '../models/classes/Role';

export class RoleHelper {
  static Roles: any[] = [
    {
      displayName: 'Company Administrator',
      value: Role.CompanyAdministrator
    },
    {
      displayName: 'Administrator',
      value: Role.Administrator
    },
    {
      displayName: 'User',
      value: Role.User
    }
  ];

  static GetExtendedRoleByNameOrNull(displayName: string): any {
    const result = RoleHelper.Roles.find((status: any) => status.value === displayName);
    if (__.IsNullOrUndefinedOrEmpty(result)) {
      return null;
    }
    return result;
  }

  static GetExtendedRoleByValueOrNull(value: Role | string): any {
    const result = RoleHelper.Roles.find((status: any) => status.value === value);
    if (__.IsNullOrUndefinedOrEmpty(result)) {
      return null;
    }
    return result;
  }
}
