import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

/**
 * This service provides useful functionalities regarding preparing filter forms to have the format that the filters need,
 * e.g. methods to create objects to filter checkboxes
 */
// @dynamic
export class FiltersFormUtilsService {
    // -----------------------------------------------------------------------------------------------------
    // @ STATIC METHODS
    // -----------------------------------------------------------------------------------------------------

    // Is needed for dynamic form controls
    static getControls(formGroup: UntypedFormGroup, name: string): AbstractControl[] {
        return (formGroup.get(name) as UntypedFormArray).controls;
    }

    /**
     * Function that is used to return a form array of a form group.
     * @param formGroup Form group which contains form arrays and other controls.
     * @param name Name of the control to find in the form group.
     */
    static getFormArray(formGroup: UntypedFormGroup, name: string): UntypedFormArray {
        return this.getAsFormArray(formGroup.get(name));
    }

    /**
     * Function that is used to return a form array of an abstract control.
     *
     * @param abstractControl the control to be switched in a formArray
     */
    static getAsFormArray(abstractControl: AbstractControl): UntypedFormArray {
        return abstractControl as UntypedFormArray;
    }

    /**
     * Deep clones the given AbstractControl, preserving values, validators, async validators, and disabled status.
     * (As seen here: https://stackoverflow.com/questions/48308414/deep-copy-of-angular-reactive-form)
     *
     * @param control AbstractControl
     * @returns AbstractControl
     */
    static cloneAbstractControl<T extends AbstractControl>(control: T): T {
        let newControl: T;

        if (control instanceof UntypedFormGroup) {
            const formGroup = new UntypedFormGroup({}, control.validator, control.asyncValidator);
            const controls = control.controls;

            Object.keys(controls).forEach(key => {
                formGroup.addControl(key, FiltersFormUtilsService.cloneAbstractControl(controls[key]));
            });

            newControl = formGroup as any;
        } else if (control instanceof UntypedFormArray) {
            const formArray = new UntypedFormArray([], control.validator, control.asyncValidator);

            control.controls.forEach(formControl => formArray.push(FiltersFormUtilsService.cloneAbstractControl(formControl)));

            newControl = formArray as any;
        } else if (control instanceof UntypedFormControl) {
            newControl = new UntypedFormControl(control.value, control.validator, control.asyncValidator) as any;
        } else {
            throw new Error('Error: unexpected control value');
        }

        if (control.disabled) {
            newControl.disable({ emitEvent: false });
        }

        return newControl;
    }
}
