/**
 * by Florian Knop, granted under MIT-License
 * https://github.com/fknop/angular-pipes/blob/master/src/math/bytes.pipe.ts
 */
import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { __ } from '../functions/object.functions';

/**
 * Transforms the date string input to a more readable format to understand how much time has passed since the date
 * in the format of just now, hours ago or days ago
 *
 * @export
 */
@Pipe({
    name: 'defaultDate'
})
export class DefaultDatePipe implements PipeTransform {

    constructor(
        private datePipe: DatePipe,
        private translateService: TranslateService
        ) { }

    transform(date: string, onlyShowDate: boolean = false, showSeconds: boolean = false): string {
        if (__.IsNullOrUndefinedOrEmpty(date)) {
            return '';
        }

        let result = `${this.datePipe.transform(date, 'dd.MM.yyyy')}`;

        if (onlyShowDate === false) {
            result = `${result} ${this.translateService.instant('DatePipe.at')} ${this.datePipe.transform(date, 'HH:mm')}`;
        }

        if (showSeconds === true) {
            result = `${result}:${this.datePipe.transform(date, 'ss')}`;
        }

        return result;
    }
}
